import React from "react";
import ImageGallery from 'react-image-gallery';
// import cloudinary from "../../../config";
// import images from "./photogalleryData";
import Hero from "../hero/HeroImage";



// function showImages() {
//   return images.map((item) => (
//     <div key={item.key} className="imageContainer">
//       <div className="img" style={{ backgroundImage: `url('${item.path}')` }} />
//       <div className="photoCaption">
//         <p>Photo Caption. {item.caption}</p>
//       </div>
//     </div>
//   ));
// }

function Gallery() {
  const images = [
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908895/Topmic/teacherWithStudents_egmzqs.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908894/Topmic/schoolParade1_yr0p9s.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908921/Topmic/playground3_lalsmy.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908920/Topmic/playground2_l5cpwm.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.04.01_c67f3e.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.04.00_ec72cq.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.59_v6o2ly.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.59_1_q3jjxl.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.58_k6thzf.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903091/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.57_ggd24p.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.57_1_qgrryb.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.56_kvpw5j.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.56_1_cr9u5p.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.55_etfoaf.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.55_1_sw07zk.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.54_hfvnui.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903090/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.53_sua887.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903089/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.52_npm2ov.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903089/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.03.16_byvg5g.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.16_z7r0zh.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.15_ijup38.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.15_3_gdlfd8.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.15_2_ac7fmm.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.15_1_scxb8k.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.14_aldcu7.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903078/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.14_3_a7asei.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903078/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.14_2_csjehq.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903078/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.14_1_hxlrts.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903079/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.13_ormqsi.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903078/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.13_2_pv11wz.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.13_1_dkwn13.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.12_r6uhch.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903078/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.12_1_ovu5kt.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.11_tesvif.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.11_4_ciijhc.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.11_3_vr2cr4.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.11_2_ptey6l.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.11_1_jdu7ux.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903077/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.10_cbqxun.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.10_2_tipojv.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.10_1_syolvr.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.09_rcoec9.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.09_2_i4n09s.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903076/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.09_1_smzvab.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903075/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.05_cjl5vv.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903075/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.01.05_1_ks8jws.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903089/Topmic/playground/WhatsApp_Image_2023-06-01_at_15.00.41_rgzvbc.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903075/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.39_xvotfx.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903075/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.39_3_go2ozp.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903075/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.39_2_oxehab.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.39_1_nq69bc.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.18_fcppli.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.18_2_kcjwth.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.18_1_clchpt.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.17_qjczvg.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903074/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.17_3_ynn0cx.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903073/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.17_2_lq5qo5.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903073/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.17_1_tyva0a.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903073/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.16_fro8ly.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903073/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.16_3_dk9slg.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903072/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.16_2_dn0pzo.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903073/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.16_1_nlmlcj.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903072/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.15_ogeqxc.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903072/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.15_2_l8iuqg.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903072/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.15_1_lbuhwv.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903072/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.14_cc9oi5.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903071/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.14_2_sx3bue.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903071/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.14_1_ho5pe7.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903071/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.13_owibsw.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903071/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.13_3_wxmjdc.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903071/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.13_2_curekf.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903070/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.13_1_aaxsap.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903070/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.12_qlfgi1.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903070/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.11_sooxdy.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903070/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.11_1_ddg4f4.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903069/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.10_oamj52.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903070/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.10_1_o9gpak.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903069/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.08_fp27ft.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903069/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.07_zp2zyg.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903069/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.05_edlf7t.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903069/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.05_1_cc8dhg.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.04_yrtd8b.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.03_ayuv9v.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.02_wito26.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.01_fg2vnj.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.59.00_b9zsyo.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1685903068/Topmic/playground/WhatsApp_Image_2023-06-01_at_14.58.59_ecrccl.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908947/Topmic/nursery3_jrfnnu.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908946/Topmic/nursery2_cuoxei.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908946/Topmic/nursery1_d4p7in.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908874/Topmic/happyChildren_gjjpww.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677915194/Topmic/happyChildren6_mak02z.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908875/Topmic/happyChildren5_nfikow.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908878/Topmic/happyChildren4_zulfd5.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908875/Topmic/happyChildren3_a2myfw.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908875/Topmic/happyChildren2_ntiqoi.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908874/Topmic/happyChild2_dqtlxa.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908873/Topmic/happyChild1_sewxtx.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908895/Topmic/hall1_g75esw.jpg'
    },
    {
      original: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908839/Topmic/graduation/graduation1_asd7ow.jpg'
    }
  ]
  // const [images, setImages] = useState([]);

  // useEffect(() => {
  //   async function fetchImages() {
  //     try {
  //       const result = await cloudinary.v2.search
  //         .expression("folder:Topmic/*")
  //         .sort_by("public_id", "desc")
  //         .max_results(100)
  //         .execute();

  //       setImages(result.resources.map((resource) => ({
  //         original: resource.secure_url,
  //       })));
        
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.error("Error retrieving resources:", error);
  //     }
  //   }

  //   fetchImages();
  // }, []);

  return (
    <div>
      <Hero style={"photogallery-img"} />
      <div id="photogallery">
        <div>
          <h2 className="photogalleryH2">Topmic Photo Gallery</h2>
          <div className="yellowBox" />
          <p className="photogalleryP">
            Explore our vibrant photo gallery and see the smiling faces of our
            students as they learn, play, and grow at Topmic. From classroom
            activities to field trips and special events, you will see the joy
            and engagement that fills our school every day.
          </p>
        </div>
        <div className="showImages">
        <ImageGallery items={images} />;
        </div>
      </div>
    </div>
  );
}

export default Gallery;