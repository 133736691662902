import React from "react";
import { connect } from "react-redux";
import { arrayOf, object } from "prop-types";

import Navbar from "./Navbar";
import ResponsiveNav from "./ResponsiveNav";

const Nav = ({ headerData }) => {
  return (
    <div className="navbar-home">
      <div className="topmic-home">
        <img
          className="header-logo"
          src="./images/logo.png"
          alt="header logo is not available!"
        />
      </div>


      <div className="header-wrapper">
        <Navbar dataNavbar={headerData[1].dataNavbar} />
      <p className="topmic-paragraph"> TOPMIC INTERNATIONAL SCHOOLS</p>
        <div id="div4" />
      </div>
      <ResponsiveNav data={headerData} />
    </div>
  );
};

function mapStateToProps({ headerData }) {
  return {
    headerData,
  };
}
// ###################### SIMPLEST FORM OF PROP-TYPES #####################

Nav.propTypes = {
  headerData: arrayOf(object.isRequired).isRequired,
};

export default connect(mapStateToProps, {})(Nav);
