const data = {
	firstPart: {
		heading: 'Achievements',
		text:
			'This page is an excellent opportunity for the school to demonstrate the hard work and dedication of its students, as well as the commitment and support of its teachers and staff. By highlighting the successes of the students, the achievement page serves as an inspiration and motivation for current and future students, encouraging them to strive for their best in all areas.'
	},
	class17Data: {
		textYear: 'Class of 2017',
		data: [
			{
				id: 1,
				textHeading: 'Growing Minds Writers Award',
				textDate: 'January 12, 2023',
				text: 'The Growing Minds Writers Award is a celebration of the amazing creativity and imagination of young writers. This competition is designed specifically for primary school children, who will have the opportunity to showcase their writing skills and be recognized for their achievements.',
				image: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677915194/Topmic/happyChildren6_mak02z.jpg'
			},
			{
				id: 2,
				textHeading: 'English Speaking Award',
				textDate: 'January 12, 2023',
				text: 'The English Speaking Award is a prestigious honor for primary school children who have demonstrated exceptional fluency and mastery of the English language. This award recognizes the hard work and dedication of young learners who have honed their English speaking skills, and it provides a platform for them to showcase their abilities to a wider audience.',
				image: 'https://res.cloudinary.com/dvcljsfid/image/upload/v1677908874/Topmic/happyChildren_gjjpww.jpg'
			}
		]
	}
};

export default data;
