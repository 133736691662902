import React, { Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Hero from '../hero/HeroImage';
import Promo from '../promo/Promo';
import Calender from '../calender/Calender';
import Gallery from '../gallery/Gallery';
import * as actions from '../../actions';


const images = ['image-1', 'image-2', 'image-3'];

class Home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0
    }
    const { getPromoAction } = this.props;
    getPromoAction();
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        currentImageIndex:
          prevState.currentImageIndex === images.length - 1
            ? 0
            : prevState.currentImageIndex + 1,
      }));
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  

  render() {
    
    const currentImageClass = images[this.state.currentImageIndex];
    return (
      <div className="Home">
        <div className="nav-divider">
          <div> 
          {/* <p className='nav-divider-content'>TOPMIC INTERNATIONAL SCHOOLS</p> */}
          
          </div>
        </div>
        <Hero style={`hero-image ${currentImageClass}`} />
        <Promo />
        <Calender />
        <Gallery />
      </div>
    );
  }
}

Home.propTypes = {
  // style: PropTypes.isRequired,
  getPromoAction: PropTypes.func.isRequired
};

export default connect(null, { getPromoAction: actions.getPromoAction })(Home);
