const data = [
	{
		id: Math.random(),
		img: 'blog-img1.jpg',
		title: 'BLOG TITLE1',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	},
	{
		id: Math.random(),
		img: 'blog-img2.jpg',
		title: 'BLOG TITLE2',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	},
	{
		id: Math.random(),
		img: 'IMG_0224.jpg',
		title: 'BLOG TITLE3',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	},
	{
		id: Math.random(),
		img: 'IMG_0348.jpg',
		title: 'BLOG TITLE4',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	},
	{
		id: Math.random(),
		img: 'IMG_9439.jpg',
		title: 'BLOG TITLE5',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	},
	{
		id: Math.random(),
		img: 'IMG_9463.jpeg',
		title: 'BLOG TITLE6',
		description:
			'Congratulations to our Year 6 boys (along with 3 of our Year 5’s) who won the WACSSA Division 1 Football Tournament today.'
	}
];
export default data;
