const data={
	counter: 0,
	events: [
		{
			id: 1,
			event: 'Half terms End',
			eventDate: '01 May',
			time: ''
		},
		{
			id: 2,
			event: 'Parent presentation on healthy living',
			eventDate: '20 May',
			time: '2:30 pm'
		},
		{
			id: 3,
			event: 'Independence Day',
			eventDate: '01 October',
			time: '12:35 pm'
		}
	]
};

export default data;