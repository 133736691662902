import React, { useState } from 'react';
import { string } from 'prop-types';

const Part = ({ text, textHeading, image, textDate }) => {
  const [showMore, setShowMore] = useState(false);
  const words = text.split(' ');
  const firstPart = `${words.slice(0, 30).join(' ')  }...`;

  return (
    <div className="part">
      <img src={image} alt="" />
      <div className="paragraph">
        <h4 className="achievement-heading">{textHeading}</h4>
        <p className='text'>{showMore ? text : firstPart}</p>
        {showMore && <p>{ " "}</p>}
        <button className="showmore" onClick={() => setShowMore(!showMore)}>
          {showMore ? ' Show Less' : 'Read More'}
        </button>
        <span>{textDate}</span>
        <div className="clear" />
      </div>
      {/* <div className="clear" /> */}
    </div>
  );
};

export default Part;

Part.propTypes = {
	text: string.isRequired,
	textHeading: string.isRequired,
	image: string.isRequired,
	textDate: string.isRequired
};
